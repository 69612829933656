<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
       
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h4 class="header-title font-weight-bold"> List of Users</h4>
                            </div>
                            <div class="col-sm-6">
                                <div class="float-sm-right">
                                    <router-link
                                            to="/user/add"
                                            class="btn btn-danger mb-2"
                                    ><i class="mdi mdi-plus-circle mr-1"></i> Add
                                        User</router-link
                                    >
                                </div>
                            </div>
                        </div>

                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="listOfUsers"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template #cell(name)="data">
                                    <span class="font-15 font-weight-bold text-dark"> {{ data.item.name}}</span>
                                </template>
                                <template #cell(email)="data">
                                    <span class="badge badge-outline-primary p-1"> {{ data.item.email}}</span>
                                </template>
                                <template #cell(phone)="data">
                                    <span class="font-12 font-weight-bold text-danger"> {{ data.item.phone}}</span>
                                </template>
                                <template #cell(gender)="data">
                                   <span
                                           class="badge "
                                           :class="{
                                          'badge-outline-info': data.item.gender === 'MALE',
                                          'badge-outline-primary': data.item.rating === 'FEMALE',
                                        }">
                                       {{ data.item.gender}}
                                   </span>

                                </template>
                                <template #cell(dateCreated)="data">
                                    <span class="font-12 font-weight-bold text-dark"> {{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template #cell(dob)="data">
                                    <span class="font-12 font-weight-bold text-dark"> {{ data.item.dob | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template #cell(actions)="data">
                                    <router-link class="btn btn-primary mr-2" style="cursor: pointer" to="#"> <i class="mdi mdi-eye mx-1"></i>View</router-link>
                                    <!--                                   :to="'/patients/'+data.item.id"-->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>

            <div class="col-lg-12">
                <div class="card">

                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
     import Layout from "../../../layouts/main";
     import PageHeader from "@/components/Page-header";
     import BaseUrl from "../../../../components/constants";
     import ToastConfigAlert from "../../../../../ToastConfig";

    export default {
       components : {
        Layout,
        PageHeader
       },
        data() { 
            return { 
                title: "Manage users",
                    items: [
                        {
                            text: "",
                        },
                        {
                            text: "Dashboards",
                        },
                        {
                            text: "Users",
                            active: true,
                        },
                    ],
                showLoader :false,
                listOfUsers : [],
                listOfUsersRaw :[],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"User name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label:"Email address",
                        key: "email",
                        sortable: true,
                    },
                    {
                        label:"Phone number",
                        key: "phone",
                        sortable: true,
                    },
                    {
                        label:"Gender",
                        key: "gender",
                        sortable: true,
                    },
                    {
                        label:"Date of birth",
                        key: "dob",
                        sortable: true,
                    },
                    {
                        label:"Date added",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            getListOfUsers() {

                BaseUrl.AxiosBearer.get("/admin/users").then((resp) => {


                    if(resp.data.status) {
                        this.listOfUsers = []

                        resp.data.payload.forEach((data) => {
                            this.listOfUsers.push({
                                id : data.id,
                                name : data.identity?.surname + " "+ data.identity?.othernames,
                                email : data.identity?.email,
                                phone : data.identity?.phoneNumber,
                                gender : data.identity?.gender,
                                dob : data.identity?.dob,
                                dateCreated : data.createdAt
                            })
                        })
                    }
                }).catch((error) => {
                    this.$bvToast.toast(error?.message, ToastConfigAlert)
                })
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        created() {
           this.getListOfUsers()
        },
        computed: {
            rows() {
                return this.listOfUsers.length;
            },
        },
    }

</script>